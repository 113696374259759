.mint-section {
  max-width: 1200px;
  margin: 20px auto; /* Adjusts vertical spacing */
  padding: 20px 30px; /* Less padding to prevent pushing elements down */
  border-radius: 15px;
  background: rgba(20, 20, 20, 0.9);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.mint-heading {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mint Box - Ensures Full Width */
.neoh_fn_mintbox {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #78f701;
  box-shadow: 0px 3px 8px rgba(120, 247, 1, 0.2);
  background: #0f0f0f;
  width: 100%;
  max-width: 1200px;
  margin-top: 0px !important; /* Remove any top margin */
  padding-top: 0px !important;
  justify-content: center;
  align-items: center;
}

/* Mint Left - Expands to Full Width */
.neoh_fn_mintbox .mint_left {
  flex: 1;
  width: 100% !important;
  padding: 10px;
  background: rgba(10, 10, 10, 0.9);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mint_list {
  display: flex;
  justify-content: center; /* Centers the grid */
  width: 100%;
}

.mint_list ul {
  display: grid;
  gap: 20px;
  list-style: none;
  justify-content: center; /* Ensures grid items are centered */
  align-items: center; /* Ensures vertical alignment */
  place-items: center; /* Short-hand for centering */
  max-width: 800px; /* Prevents excessive stretching */
  text-align: center;
}

/* Mint List Items */
.mint_list li {
  padding: 15px;
  background: rgba(40, 40, 40, 0.9);
  border-radius: 50px;
  text-align: center;
  width: 100%;
  min-width: 280px; /* Ensures proper sizing */
  max-width: 350px;
}

/* Mint List Headers */
.mint_list h4 {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 5px;
  text-transform: uppercase;
}

/* Mint List Values */
.mint_list h3 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.neoh_fn_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 35px;
  background: #000; /* Keeps button background black */
  color: #78f701; /* Default text color */
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 8px;
  border: 2px solid #78f701;
  transition: all 0.3s ease-in-out;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/* Changes text and SVG color on hover */
.neoh_fn_button:hover {
  color: #00ff88; /* New text color */
}

.neoh_fn_button:hover .fn__svg {
  fill: #00ff88 !important; /* Changes icon color */
}

/* Click Effect */
.neoh_fn_button:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 6px rgba(120, 247, 1, 0.2);
}

/* Soft Glow Effect */
.neoh_fn_button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
  transition: transform 0.4s ease-out;
}

.neoh_fn_button:hover::before {
  transform: translate(-50%, -50%) scale(1.2);
  opacity: 0;
}

/* Quantity Selection */
.qnt {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.qnt .increase,
.qnt .decrease {
  cursor: pointer;
  color: #78f701;
  font-size: 24px;
  transition: all 0.3s;
}

.qnt .increase:hover,
.qnt .decrease:hover {
  color: #00ff88;
}

/* Pagination Buttons */
.paginationBttns {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  color: #000000;
}

.paginationBttns a {
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #78f701;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.paginationBttns a:hover {
  background-color: #78f701;
  color: #000;
}

/* Responsive Design */
@media (max-width: 900px) {
  .mint_list ul {
    grid-template-columns: 1fr; /* Stacks items on smaller screens */
  }
}

@media (max-width: 768px) {
  .mint_list li {
    width: 100%;
  }
}

.fn__svg {
  fill: currentcolor;
  width: 18px;
  height: 18px;
  opacity: 0;
}
.replaced-svg {
  opacity: 1;
}
.mint-section h1 {
  margin: 40px auto;
  color: #aaa;
  width: fit-content;
}
p {
  letter-spacing: 0;
  margin-bottom: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--hc);
  font-family: var(--hff);
  line-height: 1.22;
  letter-spacing: 0;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  text-decoration: none;
  color: var(--hc);
}
h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover {
  color: var(--mc);
}
.neoh_fn_mintbox {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #252525;
  margin-bottom: 73px;
}
.neoh_fn_mintbox .mint_left {
  width: 60%;
  background-color: #0a0a0a;
  padding: 115px 50px 50px;
  position: relative;
}
.neoh_fn_mintbox .mint_right {
  width: 40%;
  padding: 115px 50px 50px;
  background-color: #080808;
}
.neoh_fn_mintbox .mint_title {
  position: absolute;
  top: 30px;
  left: 0px;
}
.neoh_fn_mintbox .mint_title span {
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--mc);
  letter-spacing: 0.5px;
  font-family: var(--hff);
  background-color: #202020;
  padding-right: 40px;
  padding-left: 50px;
  line-height: 48px;
  display: inline-block;
  white-space: nowrap;
}
.neoh_fn_mintbox .mint_title span:before {
  content: "";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 48px 0 0 40px;
  border-color: transparent transparent transparent #202020;
}
.neoh_fn_mintbox .mint_list {
  margin-bottom: 5px;
}
.neoh_fn_mintbox .mint_list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -40px;
}
.neoh_fn_mintbox .mint_list li {
  width: 50%;
  padding-left: 40px;
  margin-bottom: 45px;
}
.neoh_fn_mintbox .mint_list .item {
  height: 100%;
  border-bottom: 1px solid #252525;
  position: relative;
  padding-bottom: 10px;
}
.neoh_fn_mintbox .mint_list .item:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--mc);
  transition: all 0.3s ease;
}
.neoh_fn_mintbox .mint_list .item:hover:after {
  width: 100%;
}
.neoh_fn_mintbox .mint_list h4 {
  font-size: 16px;
  color: #666;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  margin-bottom: 1px;
  font-weight: 400;
}
.neoh_fn_mintbox .mint_list h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.neoh_fn_mintbox .mint_desc p {
  max-width: 400px;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  color: #aaa;
  margin-top: 25px;
}
.neoh_fn_mintbox .mint_desc a {
  text-decoration: none;
  color: #ccc;
}
.neoh_fn_mintbox .mint_desc a:hover {
  color: var(--mc);
}
.neoh_fn_mintbox .mint_time {
  position: relative;
  border-bottom: 1px solid #252525;
  padding-bottom: 10px;
  margin-bottom: 45px;
}
.neoh_fn_mintbox .mint_time:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--mc);
  transition: all 0.3s ease;
}
.neoh_fn_mintbox .mint_time:hover:after {
  width: 100%;
}
.neoh_fn_mintbox .mint_time h4 {
  font-size: 16px;
  color: #666;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  margin-bottom: 1px;
  font-weight: 400;
}
.neoh_fn_mintbox .mint_time h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* .neoh_fn_mintbox .mright {
  max-width: 275px;
  margin: 0 auto;
} */
.neoh_fn_mintbox .mright .featured-img {
  width: 100%;
  height: 100%;
}
.neoh_fn_mintbox .mint_checked {
  margin-bottom: 37px;
}
.neoh_fn_mintbox .mint_checked p {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 7px;
}
.neoh_fn_mintbox .mint_checked p:last-child {
  margin-bottom: 0;
}
.neoh_fn_mintbox .mint_checked .text {
  margin-right: 5px;
  font-family: var(--hff);
  font-size: 18px;
  letter-spacing: 0;
  color: #666;
}
.neoh_fn_mintbox .mint_checked .status {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  font-family: var(--hff);
  color: #aaa;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  -ms-align-items: center;
  align-items: center;
}
.neoh_fn_mintbox .mint_checked .icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: var(--mc);
  color: #000;
  border-radius: 100%;
  margin-left: 5px;
  position: relative;
  top: -2px;
}
.neoh_fn_mintbox .mint_checked .fn__svg {
  width: 8px;
  height: 8px;
  display: block;
}
.neoh_fn_mintbox .mint_info p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #aaa;
}

.neoh_fn_button {
  display: block;
  width: fit-content;
  max-width: 100%;
  text-decoration: none;
  background: transparent;
  color: var(--bc) !important;
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--hff);
  border: 2px solid var(--extra-color);
  border-radius: 5px;
  line-height: 46px;
  padding: 0 20px 0 73px;
  position: relative;
  text-align: center;
  letter-spacing: 0.5px;
}

.neoh_fn_button .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.neoh_fn_button.only_text {
  padding: 0 28px;
}

.neoh_fn_button.full {
  width: 100%;
  line-height: 66px;
}

.neoh_fn_button .icon {
  display: block;
  position: absolute;
  left: 14px;
  top: 50%;
  margin-top: -11px;
}

.neoh_fn_button .icon:after {
  content: "";
  width: 2px;
  height: 26px;
  background-color: var(--extra-color);
  left: 100%;
  margin-left: 14px;
  top: 50%;
  margin-top: -13px;
  position: absolute;
}

.neoh_fn_button .icon:before {
  content: "";
  width: 2px;
  height: 0;
  background-color: var(--mc);
  left: 100%;
  margin-left: 14px;
  top: -2px;
  position: absolute;
  z-index: 3;
  transition: all 0.3s ease;
}

.neoh_fn_button .fn__svg {
  width: 22px;
  height: 22px;
  display: block;
}

.neoh_fn_button:hover .fn__svg {
  fill: var(--mc);
}

.neoh_fn_button:hover .text {
  color: var(--mc);
}

.neoh_fn_button:hover {
  border-color: var(--mc);
  color: #eee;
}

.neoh_fn_button:hover .icon {
  fill: var(--mc);
}

.neoh_fn_button:hover {
  border-color: var(--mc);
  color: #eee;
}

.neoh_fn_button:hover .icon:after {
  opacity: 0;
}

.neoh_fn_button:hover .icon:before {
  height: 26px;
}

.qnt {
  margin: 0;
  padding: 0;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.qnt .increase,
.qnt .decrease {
  cursor: pointer;
  color: #bbb;
  transition: all 0.3s ease;
}
.qnt .increase:hover,
.qnt .decrease:hover {
  color: var(--mc);
}

.neoh_fn_mintbox .mint_list .item {
  height: 100%;
  border-bottom: 1px solid #252525;
  position: relative;
  padding-bottom: 10px;
}
.neoh_fn_mintbox .mint_list .item:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--mc);
  transition: all 0.3s ease;
}
.neoh_fn_mintbox .mint_list .item:hover:after {
  width: 100%;
}
.neoh_fn_mintbox .mint_list h4 {
  font-size: 16px;
  color: #666;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  margin-bottom: 1px;
  font-weight: 400;
}
.neoh_fn_mintbox .mint_list h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.neoh_fn_mintbox .mint_desc p {
  max-width: 400px;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  color: #aaa;
  margin-top: 25px;
}
.neoh_fn_mintbox .mint_desc a {
  text-decoration: none;
  color: #ccc;
}
.neoh_fn_mintbox .mint_desc a:hover {
  color: var(--mc);
}

.summ {
  margin: 0 0.5rem;
}

/* Responsive */
@media (max-width: 900px) {
  .neoh_fn_mintbox {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
  }
  .neoh_fn_mintbox .mint_left {
    width: 100%;
  }
  .neoh_fn_mintbox .mint_right {
    width: 100%;
    padding: 50px;
  }
  .neoh_fn_mintbox .mright {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .neoh_fn_mintbox .mint_list li {
    width: 100%;
  }
}

$width: 768px;
$bg_color: #000;
$str_color: rgb(60, 190, 112);
.header-breaker {
  height: 160px;
  @media screen and (max-width: 950px) {
    height: 220px;
  }
  @media screen and (max-width: 750px) {
    height: 240px;
  }
  @media screen and (max-width: 660px) {
    height: 270px;
  }
}
.footer-breaker {
  height: 120px;
  @media screen and (max-width: 950px) {
    height: 220px;
  }
  @media screen and (max-width: 700px) {
    height: 240px;
  }
  //   @media screen and (max-width: 660px) {
  //     height: 270px;
  //   }
}
@mixin mobile_potrait {
  @media screen and (max-width: $width) and (orientation: portrait) {
    @content;
  }
}
@mixin mobile_landscape {
  @media screen and (max-height: $width) and (orientation: landscape) {
    @content;
  }
}

@mixin select() {
  position: relative;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  @include mobile_potrait {
    width: 20%;
  }
  select {
    border: none;
    width: 100%;
    border: 1px solid var(--select-border);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.2rem;
    cursor: pointer;
    line-height: 1.1;
    border: 1px solid #0eabff;
    // background-color: #fff;
    background-color: #ffffff33;
    color: #fff;
    @include mobile_potrait {
      font-size: 0.8rem;
    }
    @include mobile_landscape {
      font-size: 0.8rem;
    }
    &::after {
      content: "";
      width: 0.8em;
      height: 0.5em;
      background-color: var(--select-arrow);
      clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    }
    &:focus {
      outline: none;
    }
    .item {
      height: 3vh;
    }
  }
}

:root {
    --sbc: #0a0a0a;
    --mc: #78f701;
    --hc: #fff;
    --bc: #ccc;
    --hff: 'Heebo', sans-serif;
    --bff: 'Nunito', sans-serif;
    --extra-color: #666;
  }
  
  body {
    margin: 0;
    padding: 0;
    background-color: var(--sbc);
  }
  
.collections-card {
  background-color: #2e2e3f; /* Slightly darker background */
  border-radius: 15px;
  width: 290px;
  height: auto; /* Let the height adjust dynamically */
  padding: 20px;
  box-sizing: border-box;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collections-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.stakeHold {
  width: 260px;
  height: auto;
}

/* Image Styling */
.collections-card img {
  width: 100%; /* Ensure full-width scaling */
  height: auto;
  border-radius: 10px;
  object-fit: cover; /* Maintain aspect ratio, crop if necessary */
}

.card-img {
  width: 100%;
  height: auto;
  position: relative;
}

.card-img input {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  z-index: 10; /* Ensure the checkbox is clickable over the image */
  cursor: pointer;
}

/* Details section */
.stake-card-details {
  color: #ffffff;
  text-align: left;
  width: 100%;
  padding: 10px 0;
}

.stake-card-details p {
  margin: 5px 0;
  font-size: 14px;
}

.stake-card-details p span {
  font-weight: bold;
  color: #00d1ff; /* Add a highlight color */
}

/* Traits Section */
.nft-traits {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1); /* Slight transparent background */
  border-radius: 10px;
}

.nft-traits h5 {
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: bold;
  color: #ffafcc; /* Different highlight color for traits */
}

.nft-traits ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nft-traits li {
  font-size: 12px;
  margin: 2px 0;
}

/* Button Styling */
.stake-button {
  background-color: #ff5f5f;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.stake-button:hover {
  background-color: #ff8585;
  transform: scale(1.05);
}

.card-item-btns {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.isStake-btns {
  justify-content: space-between;
}

/* Responsive Design */
@media screen and (max-width: 620px) {
  .collections-card {
    width: 90%;
    padding: 15px;
  }

  .collections-card img {
    max-height: 800px;
  }
}

@media screen and (max-width: 500px) {
  .collections-card {
    width: 100%;
    padding: 10px;
  }

  .stake-card-details p {
    font-size: 12px;
  }
}

.holded-nft-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(46, 46, 63, 0.7); /* Slightly transparent */
  border-radius: 15px;
  border: 2px solid rgba(102, 102, 102, 0.454);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Grid Display for NFTs */
.list-page__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  padding-top: 20px;
}

/* NFT Card Styling */
.collections-card {
  background-color: rgb(46, 46, 63);
  border-radius: 15px;
  width: 220px;
  height: auto;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collections-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.1);
}

/* NFT Image */
.collections-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* NFT Details */
.stake-card-details {
  color: white;
  font-size: 14px;
  margin-top: 10px;
}

.stake-card-details p {
  margin: 5px 0;
  font-weight: bold;
  color: #78f701;
}

/* Traits Display */
.nft-traits {
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.nft-traits h5 {
  font-size: 14px;
  margin-bottom: 5px;
  color: #ffafcc;
}

.nft-traits ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nft-traits li {
  font-size: 12px;
  margin: 2px 0;
}

/* ======== PAGINATION BUTTONS ======== */
.paginationBttns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 20px;
}

.paginationBttns li {
  list-style: none;
}

.paginationBttns a {
  padding: 12px 18px;
  border-radius: 8px;
  border: 2px solid #78f701;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
  background: rgba(46, 46, 63, 0.9);
}

.paginationBttns a:hover {
  background: #78f701;
  color: black;
  transform: scale(1.08);
  box-shadow: 0px 4px 10px rgba(120, 247, 1, 0.5);
}

/* Active pagination button */
.paginationActive a {
  background: #78f701;
  color: black;
  font-weight: bold;
  transform: scale(1.1);
}

/* Disabled pagination button */
.paginationDisabled a {
  border: 2px solid gray;
  color: gray;
  background: rgba(100, 100, 100, 0.5);
  cursor: not-allowed;
  transform: scale(1);
}

/* Responsive Design */
@media screen and (max-width: 1120px) {
  .list-page__container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media screen and (max-width: 800px) {
  .list-page__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .collections-card {
    width: 90%;
  }
}

.stake-page-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
}
.stacked-details-container {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  align-items: center;
}
.staked-page {
  display: flex;
  justify-content: space-between;
}
.stacked-details {
  width: 200px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100px;
  font-family: var(--hff);
  font-size: 16px;
  color: var(--mc);
  line-height: 46px;
  padding: 0 28px;
  border: 1px solid var(--extra-color);
  border-radius: 5px;
  transition-duration: 1s;
}
.stacked-details span,
.stacked-details p {
  line-height: 100%;
}
@media screen and (max-width: 990px) {
  .stacked-details-container {
    flex-wrap: wrap;
  }
}

.navbar-container {
  padding: 30px 0;
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: #0a0a0a;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.container {
  width: 100%;
  max-width: 1360px;
  padding: 0 80px;
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    padding: 0 40px;
  }
}
@media (max-width: 1040px) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
}

@media screen and (max-width: 640px) {
  .btn-container {
    margin-top: 3vw;
  }
}

.connect-wallet-btn {
  background-color: transparent !important;
  font-family: var(--hff);
  font-size: 16px;
  color: var(--mc);
  line-height: 46px;
  padding: 0 28px;
  border: 2px solid var(--extra-color);
  border-radius: 5px;
}

.connect-wallet-btn:hover {
  color: var(--hc);
  transition-duration: 1s;
  border: 2px solid var(--mc);
}

.connect-wallet-btn:not(:hover) {
  font-family: var(--hff);
  font-size: 16px;
  color: var(--mc);
  line-height: 46px;
  padding: 0 28px;
  border: 2px solid var(--extra-color);
  border-radius: 5px;
  transition-duration: 1s;
}

@media screen and (max-width: 480px) {
  .connect-wallet-btn {
    font-size: 3vw;
  }

  .connect-wallet-btn:not(:hover) {
    font-size: 3vw;
  }
}

.epic-gallery {
  width: 368px;
  display: flex;
  object-fit: contain;
}
